
import ReactReact, { Component } from 'react';
import calorifugeage from '../../Assets/calorifugeage.jpg'

   class Calorifugeage extends Component {


    componentDidMount = () =>{
        document.title = "Calorifugeage";
    }

       render(){
       return (
        <>
         <div className="banner_th">
                    <div className="filtre_th">
                        <div className="title_rubrique_thermique">Calorifugeage</div>
                    </div>
                </div>

                <div className="root_th">
                    <div className="txt_box_th">
                    <div className="title_th">Le calorifugeage Avec SAI</div>
                    <p>
                    Le calorifugeage est l'isolation thermique (quelquefois acoustique) des équipements 
        stockant ou transportant des fluides thermiques chauds ou froids.<br/><br/>
Comme fluides, on trouve souvent :<br/>
<i class="fas fa-arrow-circle-right"></i>	des gaz, comme l'air chaud ou froid .<br/>
<i class="fas fa-arrow-circle-right"></i>	de la vapeur d'eau saturée ou surchauffée .<br/>
<i class="fas fa-arrow-circle-right"></i>	des liquides, comme l'eau chaude ou l'eau froide .<br/>
<i class="fas fa-arrow-circle-right"></i>	des gaz liquéfiés .<br/>
<i class="fas fa-arrow-circle-right"></i>	des produits visqueux (bitume...) .<br/>
<i class="fas fa-arrow-circle-right"></i>	d'autres produits chimiques.<br/><br/>
Les équipements isolés sont des tuyauteries, des gaines, des réservoirs, des citernes fixes ou mobiles, des chaudières, des centrales de froid, des réacteurs chimiques et bien d'autres appareils ou installations.

        
                    </p>
                 </div>
                </div>


    </>
           );
       }
   }
   export default Calorifugeage;