import React from 'react';
import etude from '../../Assets/etude.jpg'
import './etude.css'
import Realisation from '../accueil/projet/realisation';
import Home_ing from '../accueil/element2/Home_ing';

    const Etude = (props) => (
        <>
            <img src={etude} width="100%" height="400px"/>
            
            
        </>
    );

export default Etude;