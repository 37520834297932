import React from 'react';
import Projet from '../accueil/projet/Projet';
import imgqualite from './../../Assets/hsee.jpg';
import qualite from './../../Assets/qualite5.jpg';
import hse from './../../Assets/hse.jpg';
import puce1 from './../../Assets/puce1.png'
import puce2 from './../../Assets/puce2.png'
import './qhse.css'

const Qhse = (props) => (
    <>

        <div className="slide_qhse">
            <div className="filtre_qhse">
                <div className="title_rubrique_qhse">
                    <p>Qualité et HSE </p>
                </div>
            </div>
        </div>




        <div className="qhse_root">
            <div className="left_qhse">
                <div className="left_top_qhse">
                    <div className="entete_qhse_left">
                        {/* <img src={logo} width="45px" />&nbsp; &nbsp; */}
                        <div className="etlaqualite">SAI et la QUALITE</div>
                    </div>
                    <div className="txt_qualite_qhse">
                    <img src={puce1}/>
                    <div>
                        "QUAND NOUS OFFRONS LA QUALITE AUX CLIENTS ILS
                        ne font pas que revenir vers nous, ils ne font pas que nous recommander,
                        ils insistent pour que leurs amis fassent affaire avec nous."
                        </div>
                    
                        <img src={puce2} className="puce2"/>
                        <div className="rochdi_fondateur">
                        <div className="rochdi">Mr. Rochdi JABBERI</div>
                        <div className="fondateur">Fondateur SAI</div>
                        </div>
                      
                    </div>
                </div>
                <div className="left_bottom_qhse">

                </div>
            </div>
            <div className="right_qhse">
                <div className="right_top_qhse">
                    <div className="entete_qhse_left">

                        <div className="etlaqualite">SAI & HSE</div>
                    </div>
                    <div className="txt_qualite_qhse">
                    <img src={puce1}/>
                        <div className="slogan_hse"> Nos employés sont le cœur de notre entreprise."</div>
                        C'est pourquoi nous accordons la priorité à la sécurité de tous nos actifs ;
                        Pourquoi nous cherchons à minimiser nos impacts sur l'environnement et les sociétés ;
                        Et pourquoi nous visons à attirer des employés qui s'efforcent d'être des leaders dans leur domaine..<br/>
                        <img src={puce2} className="puce2"/>
                        <div className="resp_hse">Responsable HSE</div>
                    </div>
                </div>
                <div className="right_bottom_qhse">

                </div>
            </div>

        </div>







    </>
);

export default Qhse;