import React, { Component } from 'react';
import './new_ing.css';
import { NavLink } from 'react-router-dom';
import calorifugeage from '../../Assets/calorif.jpg'
import thermique from '../../Assets/thermique.jpg'
import frigorifique from '../../Assets/frigo.JPG'
import acoustique from '../../Assets/acoust.jpg'
import ing from './../../Assets/hsee.jpg'
import aaa from './../../Assets/ele22.jpg'



class IngenierieN extends Component {
    render() {
        return (
            <>
                <div className="bg_entete"><img src={aaa} width="100%" height="100%" /><div className="slogan_ing">Ingenierie</div></div>
                <div className="entete_ing">
                    <div className="trait_deco"></div>
                    <p>

                        {/* <h3>Ingenierie</h3> */}
                        Le calorifugeage consiste, par définition,
                        à isoler des installations de circulation d’eau chaude ou de chauffage (tuyauteries, réservoirs, citernes...)
                        afin d’éviter les pertes de chaleur. Cela permet, entre autre, de protéger les installations contre
                        les perturbations extérieures telles que le gel, la condensation ou le givre, mais aussi de conserver
                        l'énergie thermique et de limiter les rejets des gaz à effet de serre. Après la description des principes
                        thermiques et des phénomènes physiques, cet article détaille les méthodes pour réaliser efficacement le calorifugeage d'équipements.
                        Sont également présentés les différents matériaux isolants (laine de verre, liège, polystyrènes, mousses phénoliques, verre cellulaire...)
                    </p>
                </div>

                <div className="fond2">
                    <div class="row  ing_element">
                      
                            <div class="col-12 col-md-5 col-xl-5 col-xxl-5">
                                <div class="image_ing"><img src={aaa} alt="thermique" /></div></div>
                            <div class="col-12 col-md-7 col-xl-7 col-xxl-7">
                                <div class="txt_ing">
                                    <div className="trait_deco_description"> </div>
                                    <div className="description_ing">
                                        <div>
                                            <NavLink to="/thermique" exact>
                                                <h5>thermique</h5>
                                            </NavLink>
                                        </div>
                                        <p>  L’isolation thermique désigne l'ensemble des techniques mises
                                            en œuvre pour limiter les transferts de chaleur entre un milieu chaud
                                            et un milieu froid. L'isolation thermique est utilisée dans
                                            de nombreux domaines incluant notamment ...</p>
                                        <NavLink to="/thermique" exact>
                                            <button class="btn_ing">Savoir Plus&nbsp;<i class="fas fa-arrow-right"></i></button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        
                    </div>

                    <div class="row  ing_element">
                        <div class="col-12 col-md-5 col-xl-5 col-xxl-5">
                            <div class="image_ing"><img src={aaa} alt="calorifugeage" /></div></div>
                        <div class="col-12 col-md-7 col-xl-7 col-xxl-7">
                            <div class="txt_ing">
                                <div className="trait_deco_description"> </div>
                                <div className="description_ing">
                                    <div>
                                        <NavLink to="/frigorifique" exact>
                                            <h5>frigorifique</h5>
                                        </NavLink>
                                    </div>
                                    <p>  L’isolation thermique désigne l'ensemble des techniques mises en œuvre
                                        pour limiter les transferts de chaleur entre un milieu chaud et un milieu
                                        froid. L'isolation thermique est utilisée dans de nombreux domaines ...
                                    </p>
                                    <NavLink to="/frigorifique" exact>
                                        <button class="btn_ing">Savoir Plus&nbsp;<i class="fas fa-arrow-right"></i>
                                        </button>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row  ing_element">
                        <div class="col-12 col-md-5 col-xl-5 col-xxl-5">
                            <div class="image_ing"><img src={aaa} alt="calorifugeage" /></div></div>
                        <div class="col-12 col-md-7 col-xl-7 col-xxl-7">
                            <div class="txt_ing">
                                <div className="trait_deco_description"> </div>
                                <div className="description_ing">
                                    <div><NavLink to="/calorifugeage" exact><h5>calorifugeage</h5></NavLink> </div>
                                    <p>Le calorifugeage est l'isolation thermique (quelquefois acoustique) des équipements stockant
                                        ou transportant des fluides thermiques chauds ou froids...
                                    </p><NavLink to="/calorifugeage" exact>
                                        <button class="btn_ing">Savoir Plus&nbsp;<i class="fas fa-arrow-right"></i></button></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row  ing_element">
                        <div class="col-12 col-md-5 col-xl-5 col-xxl-5">
                            <div class="image_ing"><img src={aaa} alt="calorifugeage" /></div></div>
                        <div class="col-12 col-md-7 col-xl-7 col-xxl-7">
                            <div class="txt_ing">
                                <div className="trait_deco_description"> </div>
                                <div className="description_ing">
                                    <div><NavLink to="/aeraulique" exact><h5>aeraulique</h5></NavLink></div>
                                    <p> SAI conçoit, fabrique dans ces ateliers  et installe des gaines de ventilation,
                                        conduits de fumée et coupe-feu  pour les industries et les collectivités
                                        Notre savoir-faire...</p>
                                    <NavLink to="/aeraulique" exact> <button class="btn_ing">Savoir Plus&nbsp;<i class="fas fa-arrow-right"></i></button></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row  ing_element">
                        <div class="col-12 col-md-5 col-xl-5 col-xxl-5">
                            <div class="image_ing"><img src={aaa} alt="acoustique" /></div></div>
                        <div class="col-12 col-md-7 col-xl-7 col-xxl-7">
                            <div class="txt_ing">
                                <div className="trait_deco_description"> </div>
                                <div className="description_ing">
                                    <div><NavLink to="/acoustique" exact><h5>acoustique</h5></NavLink></div>
                                    <p>
                                        Le calorifugeage est l'isolation thermique (quelquefois acoustique)
                                        des équipements stockant ou transportant des fluides thermiques chauds ou froids.
                                    </p>
                                    <NavLink to="/acoustique" exact>
                                        <button class="btn_ing">Savoir Plus&nbsp;<i class="fas fa-arrow-right"></i>
                                        </button>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>

        );
    }
}
export default IngenierieN;
