import ReactReact, { Component } from 'react';
import thermique from '../../Assets/thermique.jpg'
import './thermique.css'

class Thermique extends Component {


    componentDidMount = () => {
        document.title = "isolation Thermique";
    }

    render() {
        return (
            <>
                <div className="banner_th">
                    <div className="filtre_th">
                        <div className="title_rubrique_thermique">isolation Thermique</div>
                    </div>
                </div>

                <div className="root_th">
                    <div className="txt_box_th">
                    <div className="title_th">L'Isolation Thermique Avec SAI</div>
                    <p>
                        L’isolation thermique désigne l'ensemble des techniques mises en œuvre pour limiter les transferts de chaleur entre
                        un milieu chaud et un milieu froid. L'isolation thermique est utilisée dans de nombreux domaines incluant notamment :
                        le bâtiment (maintien d'une température de confort à l'intérieur d'un bâtiment en dépensant le minimum d'énergie),
                        l'industrie, l'automobile, la chaîne du froid, la cuisine et le textile (sur terre, dans l'espace, sous la mer1...)
                    </p>
                 </div>
                </div>
            </>
        );
    }
}
export default Thermique;