import React, { Component } from 'react';
class Expertise extends Component {
    render(){
    return (
<>
Nous sommes une Équipe d’experts et de professionnels venant de secteurs différents, possédant des compétences variées et complémentaires. 
Mais notre volonté est commune : vous apporter les meilleures solutions pour vos besoins, vous garantir la qualité et honorer nos prestations dans les règles de l’Art en respectant la sécurité́ et l’environnement. 
Depuis sa création, SAI n’a cessé d’évoluer, de développer, de produire et trouver des solutions adéquates pour ses clients
Avec une expérience de plus de 20 ans, nous proposons aux professionnels de l’industrie et de bâtiments des prestations de qualité pour l’isolation thermique, frigorifique aussi la fabrication et pose de gaine de ventilation

</>
        );
    }
}
export default Expertise;
