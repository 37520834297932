import React, { Component } from 'react';
import acoustique from '../../Assets/acoustique.jpg'

class Acoustique extends Component {

    componentDidMount = () => {
        document.title = "Acoustique";
    }

    render() {
        return (
            <>

                <div className="banner_th">
                    <div className="filtre_th">
                        <div className="title_rubrique_thermique">acoustique</div>
                    </div>
                </div>

                <div className="root_th">
                    <div className="txt_box_th">
                        <div className="title_th">L'Isolation acoustique Avec SAI</div>
                        <p>
                        <div className="row"></div>
        Le bruit fait partie des principales nuisances du monde contemporain. De nombreuses règlementations visent à limiter le niveau sonore, que ce soit dans les ERP, sur les lieux de travail ou encore dans les bâtiments à usage d’habitation. 
Pour être efficace, l’isolation acoustique doit s’adapter aux différents bruits produits et aux caractéristiques de chaque bâtiment et des activités qu’il abrite.

Nous assurons l’isolation acoustique pour : <br/><br/>
            <div className="row">
                <div className="col-12 col-md-6  col-xl-6 col-xxl-6">
                <i class="fas fa-arrow-circle-right"></i> l’industrie<br/>
L’environnement industriel est souvent générateur de bruit : machines outil, chaines de production, ventilation, sirènes et alarmes sont bruyantes. <br/>
L’isolation acoustique dans l’industrie vise aussi bien à amoindrir les bruits de l’intérieur vers l’extérieur, que ceux se transmettant entre les différentes zones intérieures.<br/>

 Elle est réalisée au moyen d’isolants classiques :<br/>
 laine de roche, habillage ou panneau acoustique, isolant sous chape en caoutchouc, isolant en dalles, mousse de polyuréthane etc.<br/>
                </div>
                <div className="col-12 col-md-6  col-xl-6 col-xxl-6">
                <i class="fas fa-arrow-circle-right"></i> pour les voiries<br/>
Les bruits de trafic routier ou ferroviaires causent des nuisances sonores aux riverains. La mise en place de murs anti bruit permet de les limiter à condition d’avoir une hauteur suffisante. 
Ils peuvent être en divers matériaux et agrémentés de végétation pour une esthétique plus agréable.
<br/><br/>
<i class="fas fa-arrow-circle-right"></i> l’habitat et les ERP<br/>
L’isolation acoustique de l’habitat concerne aussi bien les bruits extérieurs que les bruits intérieurs qui peuvent être aériens, d’impact ou d’équipement. Double vitrage, isolants et faux plafond permettront d’y remédier.
</div>   </div>
                        </p>
                    </div>
                </div>
       
            </>
        );
    }
}
export default Acoustique;
