import React from 'react';
import Logo from '../../src/Assets/logo.png';
import rect from '../../src/Assets/rect_menu.jpg';
import tel from '../../src/Assets/tel.png';
import { NavLink } from 'react-router-dom';
import './menu.css';



const Menu = (props) => (
  <div className="menu_style">
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <NavLink className="navbar-brand" to="/" exact>
        <img src={Logo} alt="logo du site" className="logo" /></NavLink>

      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      {/* <img src="https://img.icons8.com/ios/50/000000/home-screen.png"/> */}
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto ul_menu">

          <li className="nav-item">
            <NavLink  to="/" exact className="nav-link" exact><div className="title_rubrique"><img src="https://img.icons8.com/ios/30/000000/home-screen.png" /></div></NavLink>
          </li>

          <li className="nav-item">
            <NavLink to="/quisommesnous" exact className="nav-link" exact><div className="title_rubrique">A propos</div></NavLink>
          </li>
          <li className="nav-item">

            <NavLink to="/ingennierie" exact className="nav-link" exact><div className="title_rubrique"><div className="rubrique_ing">Ingenierie</div></div></NavLink>


          </li>


          {/* <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle aaa" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
        <NavLink to="/thermique" exact className="nav-link"><div className="ing-nav">Thermique</div></NavLink>
     <NavLink to="/frigorifique" exact className="nav-link"><div className="ing-nav">Frigorifique</div></NavLink>
     <NavLink to="/calorifugeage" exact className="nav-link"><div className="ing-nav">Calorifugeage</div></NavLink>
     <NavLink to="/aeraulique" exact className="nav-link"><div className="ing-nav">Aeraulique</div></NavLink>
     <NavLink to="/acoustique" exact className="nav-link"><div className="ing-nav">Acoustique</div></NavLink>
        </div>
      </li> */}

          <li className="nav-item">
            <NavLink to="/services" exact className="nav-link" exact><div className="title_rubrique">Services</div></NavLink>
          </li>
          {/* <li className="nav-item">
            <NavLink to="/services" exact className="nav-link" exact><div className="title_rubrique">Produits</div></NavLink>
          </li> */}
          <li className="nav-item">
            <NavLink to="/projet" exact className="nav-link" exact><div className="title_rubrique">Nos Realisations</div></NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/qhse" exact className="nav-link" exact><div className="title_rubrique">Qualité & HSE</div></NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/contact" exact className="nav-link" exact><div className="title_rubrique">Contact</div></NavLink>
          </li>
        </ul>

      </div>
    </nav>
  </div>

);

export default Menu;