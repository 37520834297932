import React from 'react';
import bizerte from './../../Assets/ciment\ bizerte.jpg';



import './nosrealisation.css'
{/* <img src={bizerte} height="300px" width="600px" /> */ }
const Nosrealisations = (props) => (
    <div className="nosrealisations">

        <div className="entete_nr">
            <div className="bar_deco"></div>
            <div className="txt_entete_nr">
                <div className="titre_page_projet">NOS PROJETS</div>
                <div className="slogan_page_projet">Nous avons mené à bien de nombreux projets dans les différents
                    secteurs d’activité de notre entreprise.</div>
                    </div>
        </div>
        <div class="root_nr">


            <div class="realisation">
                <div class="card">
                    <span></span>
                    <div class="imgBx"><img src={bizerte} /></div>
                    <div className="overlay"></div>
                    <div class="content">
                        <div>
                            <h2>Card Title</h2>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                Eligendi ipsam pariatur temporibus quo! Placeat cupiditate
                                doloribus perferendis odit nam,
                                aperiam eius</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="realisation">
                <div class="card">
                    <span></span>
                    <div class="imgBx"><img src={bizerte} /></div>
                    <div className="overlay"></div>
                    <div class="content">
                        <div>
                            <h2>Card Title</h2>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                Eligendi ipsam pariatur temporibus quo! Placeat cupiditate
                                doloribus perferendis odit nam,
                                aperiam eius</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="realisation">
                <div class="card">
                    <span></span>
                    <div class="imgBx"><img src={bizerte} /></div>
                    <div className="overlay"></div>
                    <div class="content">
                        <div>
                            <h2>Card Title</h2>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                Eligendi ipsam pariatur temporibus quo! Placeat cupiditate
                                doloribus perferendis odit nam,
                                aperiam eius</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="realisation">
                <div class="card">
                    <span></span>
                    <div class="imgBx"><img src={bizerte} /></div>
                    <div className="overlay"></div>
                    <div class="content">
                        <div>
                            <h2>Card Title</h2>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                Eligendi ipsam pariatur temporibus quo! Placeat cupiditate
                                doloribus perferendis odit nam,
                                aperiam eius</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="realisation">
                <div class="card">
                    <span></span>
                    <div class="imgBx"><img src={bizerte} /></div>
                    <div className="overlay">
                        <div class="content">
                            <div>
                                <h2>Card Title</h2>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                    Eligendi ipsam pariatur temporibus quo! Placeat cupiditate
                                    doloribus perferendis odit nam,
                                    aperiam eius</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="realisation">
                <div class="card">
                    <span></span>
                    <div class="imgBx"><img src={bizerte} /></div>
                    <div className="overlay"></div>
                    <div class="content">
                        <div>
                            <h2>Card Title</h2>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                Eligendi ipsam pariatur temporibus quo! Placeat cupiditate
                                doloribus perferendis odit nam,
                                aperiam eius</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="realisation">
                <div class="card">
                    <span></span>
                    <div class="imgBx"><img src={bizerte} /></div>
                    <div className="overlay"></div>
                    <div class="content">
                        <div>
                            <h2>Card Title</h2>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi ipsam pariatur temporibus quo! Placeat cupiditate doloribus perferendis odit nam,
                                aperiam eius</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Nosrealisations;