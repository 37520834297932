import React from 'react';
import { NavLink } from 'react-router-dom';
import img1 from './Imgs/img1.jpg';
import img2 from './Imgs/img2.jpg';
import img3 from './Imgs/img3.jpg';
import bizerte from './caroussel-imgs/1.jpg';
import Tunis from './caroussel-imgs/2.jpg';
import sousse from './caroussel-imgs/3.jpg';
import Zaghouen from './caroussel-imgs/4.jpg';
import './realisation.css'
const Realisation = (props) => (
    <>
      <div className="row root-realisation">
      <div className="col-12 col-md-6  col-xxl-6 col-xl-6 left">
          <div className="entete_realisation">
          <div className="bar_realisation"></div>
                    <div className="title_realisation">NOS PROJETS</div>
                    <div className="slogan_realisation">Nous avons mené à bien de nombreux projets dans les différents
                        secteurs d’activité de notre entreprise.</div>
                    <button class="btn_ralisation">TOUS LES PROJETS&nbsp;<i class="fas fa-arrow-right"></i></button>
          </div>
          <div className="realisation_left">
            <div className="bizerte_realisation" ><div className="overlay_realisation"></div><div className="p_realisation">Cimenterie de Bizerte</div></div>
          </div>

      </div>
      <div className="col-12 col-md-6  col-xxl-6 col-xl-6 right">
          <div className="right_realisation_1">
      <div className="sousse_realisation" ><div className="overlay_realisation"></div><div className="p_realisation">Centrale de Sousse</div></div>
      </div>
      <div className="right_realisation_2">
      <div className="zaghouane_realisation" ><div className="overlay_realisation"></div><div className="p_realisation">Cimenterie de jbal oust</div></div>
      </div>
      </div>
      </div>
    </>
);

export default Realisation;