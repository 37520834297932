import React, { Component } from 'react';
import './domaineapplicatio.css';

class Domainedapplication extends Component {
    render(){
    return (
<>
<div className="root_home_da" >
            <div className="filtre_da">
                <div className="entete_da">
                    <div className="title1_da">Domaine d'Application</div>
                    <div className="desc_da">SAI et son équipe compétente offrent
                                tout leur savoir-faire en matière d’installation,
                                réparation et rénovation en calorifuge
                                et isolation thermique dans les secteurs suivants</div>
                </div>
                <div className="activite_da">

                    <div className="energie_da">
                        <i class="fas fa-fire icon"></i>
                        <div className="secteur_da">energie</div>
                        <div className="desc_secteur_da"> pétrole, gaz, pétrochimie, centrales électriques</div>
                    </div>

                    <div className="agroalimentaire_da">
                        <i class="fas fa-leaf icon"></i>
                        <div className="secteur_da">agroalimentaire</div>
                        <div className="desc_secteur_da"> fabrication du sucre, des produits alimentaires</div>
                    </div>

                    <div className="batiments_da">
                        <i class="fas fa-building icon"></i>
                        <div className="secteur_da">batiment</div>
                        <div className="desc_secteur_da"> industriels, administratifs, hôtels, hôpitaux</div>
                    </div>
                    <div className="industries_da">
                        <i class="fas fa-industry icon"></i>
                        <div className="secteur_da">industries</div>
                        <div className="desc_secteur_da"> chimiques, pharmaceutiques, cimenteries électronique</div>
                    </div>

                    
                </div>

            </div>
        </div>

</>
        );
    }
}
export default Domainedapplication;
