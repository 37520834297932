
import ReactReact, { Component } from 'react';
import calorifugeage from '../../Assets/frigorifique.jpg'

   class Frigorifique extends Component {


    componentDidMount = () =>{
        document.title = "Frigorifique";
    }

       render(){
       return (
        <>

<div className="banner_th">
                    <div className="filtre_th">
                        <div className="title_rubrique_thermique">frigorifique</div>
                    </div>
                </div>

                <div className="root_th">
                    <div className="txt_box_th">
                    <div className="title_th">Le frigorifique Avec SAI</div>
                    <p>
                    Les tuyauteries d’eau froide doivent être isolées pour éviter la condensation et la formation de givre ou de glace.
Nous sommes des professionnels de l’isolation frigorifique , montage de panneaux frigorifique, de chambres froides, de salles blanches, de laboratoires, menuiseries isothermes
   
                    </p>
                 </div>
                </div>

{/* 
         <div className="calo_child">
         <div className="three_child"><i class="fas fa-snowflake iconfa"></i>frigorifique</div>
         <div className="two_child"></div>
         <div className="one2_child"></div>
         </div>
         <div className= "txt_ing_child">
         <div className="descr_child">
         Les tuyauteries d’eau froide doivent être isolées pour éviter la condensation et la formation de givre ou de glace.
Nous sommes des professionnels de l’isolation frigorifique , montage de panneaux frigorifique, de chambres froides, de salles blanches, de laboratoires, menuiseries isothermes
   
         </div>
         </div>

         */}


    </>
           );
       }
   }
   export default Frigorifique;