import React from 'react';
import './qs.css';
import fond from './fond.png';
import { NavLink } from 'react-router-dom';


const Qs = (props) => (
    <>

        <div className="root_qs">
            <div className="left_qs">
                <div className="left_qs_top">
                    <div className="entete_qs">
                        <div className="bar-color-qs"></div>
                        <div className="title_slogan_qs">
                            <div class="title_qs">SERVICES ET ACTIVITES D'ISOLATION
                            </div>
                            <div class="slogan_qs"> L'une des plus importantes <br />
                                sociétés de calorifugeage en Tunisie
                            </div>
                        </div>
                    </div>
                    <div class="txt_qs">
                        <p>
                            Fondée en 2009 en tant que société de
                            services,nous sommes devenus un important
                            spécialiste dans le domaine du calorifugeage,
                            isolation frigorifique et la fabrication
                            et pose de gains de ventilation.
                        </p>
                    </div>
                </div>
                <div className="left_qs_bottom">
                    <p className="txt_qs">
                        Nous activités comprenant plusieurs
                        spécialités et prestations.Notre but est
                        d'executer notre travail de maniere
                        responsables dans les régles de l'art en
                        respectant la sécurité et l'environnement.
                    </p>
                   <div className="button_qs">
                        <button class="teslabtn_qs"><NavLink to="/quisommesnous" exact className="link-button_qs">Plus de details</NavLink></button>
                        </div>
                </div>
            </div>
            <div className="right_qs">
                <div className="right_qs_top">
                    <div className="service_item"><i class="fas fa-arrow-circle-right icon_home"></i>&nbsp;Etude</div>
                    <div className="service_item"><i class="fas fa-arrow-circle-right icon_home"></i>&nbsp;Installation</div>
                </div>
                <div className="right_qs_bottom">
                    <div className="service_item"><i class="fas fa-arrow-circle-right icon_home"></i>&nbsp;Maintenance</div>
                    <div className="service_item"><i class="fas fa-arrow-circle-right icon_home"></i>&nbsp;Controle</div>
                </div>
            </div>

        </div>
    </>


);

export default Qs
