import React, { Component } from 'react';
import img_qn from '../../Assets/calorifugeage.jpg';
import Domainedapplication from './Domainedapplication';
import Nosvaleurs from './Nosvaleurs';
import { NavLink } from 'react-router-dom';
import './qn.css';



class Quisommesnous extends Component {
    render() {
        return (
            <>
                <div className="bannerqn">
                    <div className="threeqn"> Qui Sommes Nous </div>
                    <div className="twoqn"></div>
                    <div className="oneqn"></div>
                </div>



                <div className="element_1">
                    <div className="row col-12 titre_org">
                        <div className="bar-color"></div>
                        Nous somme La société SAI <br /> (Services et Activités d’Isolation),<br /></div>
                    <div className="row col-12 titre_slogan">
                        l’une des plus importantes sociétés <br /> de calorifugeage en Tunisie<br />
                    </div>
                    <div className="row img_text">
                        <div className="col-12 col-md-7  col-xxl-7 col-xl-7  text-entete">
                            <br />
                            Fondé en 2009 en tant que société de service,<br />
                            nous sommes devenus un important spécialiste dans le domaine du calorifuge,<br />
                            isolation frigorifique et la fabrication et pose de gaine de ventilation.<br />
                            <br />
                            Nos activités comprennent plusieurs spécialités et prestations. <br />
                            Notre but est d’exécuter notre travail de manière responsable dans les règles<br />

                            de l’art en respectant la sécurité́ et l’environnement.<br />
                            Nous remplissons notre objectif en respectant notre stratégie d’être actif à chaque<br />
                            étape de projet.

                            Notre diversité par spécialité, produit et activité, maximise la valeur<br />
                            que nous créons pour notre entreprise et ses diverses parties prenantes.

                        </div>
                        <div className="col-12 col-md-5  col-xxl-5 col-xl-5  small_slide">
                            <div className="image_qn" ><img src={img_qn} className="forme_img" />
                            </div><div className="form_color"></div>
                        </div>

                    </div>

                </div>


                <div className="pourquoinouschoisir">


                    <div className="form_color_3"></div>
                    <div className="row title">POURQUOI NOUS CHOISIR</div>

                    <div className="row descr">Parce que votre temps est précieux, nous sommes votre interlocuteur dans vos
                        projets et gérons les intervenants de A à Z.<br />
                        Parce que vos attentes et vos besoins sont uniques, nous concevons les interventions sur mesure.</div>
                    <div className="row nosvaleurs" >
                        <div className="col-12 col-md-6  col-xxl-6 col-xl-6 nosvaleurs1">
                            <div className="font-weight-bolder">  <i class="fas fa-arrow-right"></i>&nbsp;ÉCOUTE<br /> </div>
                            Nous sommes avant tout là pour entendre vos besoins<br />
                            et vos attentes afin de mettre en place, avec vous,<br />
                            la stratégie de prise en charge la plus adaptée<br />
                            <br />
                            <div className="font-weight-bolder"> <i class="fas fa-arrow-right"></i>&nbsp;EXPÉRIENCE<br /></div>
                            De part nos formations initiales, nos 20 ans d’expérience<br />
                            professionnelle dans le monde industriel et de l’entreprise,<br />
                            et nos spécialisations en calorifugeage, en isolation<br />
                            thermique et ventilation, nous pouvons vous aider <br />
                            à réaliser vos projets.<br />
                            <br />
                            <div className="font-weight-bolder">  <i class="fas fa-arrow-right"></i>&nbsp;PROFESSIONNALISME<br /> </div>
                            Nous et nos partenaires intervenons selon notre charte <br />
                            de confidentialité et notre code de déontologie que nous<br />
                            signerons avec vous.<br />
                            <br />
                            <div className="font-weight-bolder">  <i class="fas fa-arrow-right"></i>&nbsp; ACCOMPAGNEMENT<br /> </div>
                            Les solutions que nous vous proposons sont élaborées<br />
                            avec vous tout au long de notre intervention, avec <br />
                            une revue au mois, au trimestre ou à l’année.<br />
                        </div>
                        <div className="col-12 col-md-6  col-xxl-6 col-xl-6 nosvaleurs2">


                            <div className="font-weight-bolder"> <i class="fas fa-arrow-right"></i>&nbsp;ADAPTABILITÉ <br /></div>
                            Nous disposons d’un panel d’outils et de techniques qui nous<br />
                            permettent de vous proposer des solutions adaptées à vos attentes<br />
                            et de prendre en charge des publics sensibles tels que<br />
                            femmes enceintes et personnes pathologiques.<br />
                            <br />
                            <div className="font-weight-bolder"> <i class="fas fa-arrow-right"></i>&nbsp;DYNAMISME<br /></div>
                            Nous cherchons en permanence de nouvelles compétences <br />
                            et de nouveaux experts afin de toujours mieux adapter nos solutions,<br />
                            de les pousser toujours plus loin dans l’efficacité et la durabilité.<br />
                            <br />
                            <div className="font-weight-bolder"> <i class="fas fa-arrow-right"></i>&nbsp;FLEXIBILITÉ<br /></div>
                            Nous pouvons intervenir sur une ou plusieurs journées en fonction<br />
                            du nombre de bénéficiaires à prendre en charge.<br /><br />
                            <div className="font-weight-bolder"><i class="fas fa-arrow-right"></i>&nbsp; Rigueur, fiabilité et proximité</div><br /><br />
                            <div className="font-weight-bolder"><i class="fas fa-arrow-right"></i>&nbsp;Prix compétitifs et bien étudié</div><br /><br />
                            <div className="font-weight-bolder"> <i class="fas fa-arrow-right"></i>&nbsp; Qualité garantie</div><br />

                        </div>
                    </div>





                </div>

                
                <div className="expertise">
                    <div className="row col-12 titre_org_2">
                        <div className="form_color_2_2"></div>
                        EXPERTISE</div>
                    <div className="row col-12 titre_slogan_2">
                        Nous sommes une Équipe d’experts <br />
                        et de professionnels
                    </div>
                    <div className="row img_text_2">
                        <div className="col-12 col-md-7  col-xxl-7 col-xl-7  text-entete_2">
                            de secteurs différents, possédant des compétences variées et complémentaires.<br />
                            Mais notre volonté est commune : vous apporter les meilleures solutions<br />
                            pour vos besoins, vous garantir la qualité et honorer nos prestations dans<br />
                            les règles de l’Art en respectant la sécurité́ et l’environnement.<br />
                            Depuis sa création, SAI n’a cessé d’évoluer, de développer, de produire<br />
                            et trouver des solutions adéquates pour ses clients Avec<br />
                            une expérience de plus de 20 ans, nous proposons aux professionnels <br />
                            de l’industrie et de bâtiments des prestations de qualité pour l’isolation<br />
                            thermique, frigorifique aussi la fabrication et pose de gaine de ventilation.

                        </div>
                        <div className="col-5 small_slide_2">
                            <div className="image_qn_2" ><img src={img_qn} className="forme_img_2" />
                            </div><div className="form_color_2"></div>
                        </div>

                    </div>
                </div>

                <Domainedapplication />

                {/* <div className="da">
                    <div className="filtre_da">
                        <div className="entete_da">
                            <div className="row title_da">Domaine d'Application</div>
                            <div className="row slogan_da">
                                SAI et son équipe compétente offrent
                                tout leur savoir-faire en matière d’installation,
                                réparation et rénovation en calorifuge
                                et isolation thermique dans les secteurs suivants
                            </div>
                        </div>
                        <div className="row activity">
                            <div className="col-12 col-md-6 col-xl-3 col-xxl-3 energie">
                                <i class="fas fa-fire icon"></i>
                                <br /><div className="secteur">ENERGIES</div>
                                <div className="desc_secteur"> pétrole, gaz, pétrochimie <br />et centrales électriques</div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-3 col-xxl-3 energie">
                                <i class="fas fa-industry icon"></i>
                                <br /><div className="secteur">industries</div>
                                <div className="desc_secteur"> chimiques, pharmaceutiques, cimenteries électronique</div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-3 col-xxl-3 energie">
                                <i class="fas fa-leaf icon"></i>
                                <br /><div className="secteur">agroalimentaires</div>
                                <div className="desc_secteur"> fabrication du sucre,<br /> des produits alimentaires</div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-3 col-xxl-3 energie">
                                <i class="fas fa-building icon"></i>
                                <br /><div className="secteur">batiments</div>
                                <div className="desc_secteur"> industriels, administratifs,<br /> hôtels, hôpitaux</div>
                            </div>
                        </div>

                    </div>
                </div> */}

            </>
        );
    }
}
export default Quisommesnous;
