import React, { Component } from 'react';
import './contact.css';
import Formulaire from './Formulaire';
import Maps from './Maps';

class Contact extends Component {

    componentDidMount = () =>{
        document.title = "Contact";
    }

    render(){
    return (
<>
     <div className="root_contact">
         <div className="left_contact">
             <div className="left_top_contact">
             <div className="title_contact">contact</div>
             <div className="trait_contact"></div>
             <div className="entete_contact container">
             <div className="adr_contact">Av H.Bourguiba,
                  Imm LACHKAR étage 3
                   -Menzel Jemil, 7080
                   </div>
                   <div className="tél">Téléphone</div>
                   <div className="num_tél">+216 72 492 707</div>
                   <div className="email">E-mail</div>
                   <div className="adr_email">contact@sai-isolation.com</div>
                   </div>
                   </div>
            <div className="form_contact"><Formulaire/></div> 
         </div>
         <div className="right_contact">
         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3176.384979967721!2d9.911371214144548!3d37.23857245060207!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12e2e023a3945e7d%3A0x14eff26c57239098!2sSAI%20(SERVICES%20ET%20ACTIVITES%20D&#39;ISOLATION)!5e0!3m2!1sfr!2stn!4v1633007286820!5m2!1sfr!2stn" loading="lazy"></iframe>
         </div>
     </div>
    
</>
        );
    }
}
export default Contact;

