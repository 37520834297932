import React, { Component } from 'react';
import Slide2 from './Slider/Slide2';
import Qs from './element3/Qs';
import Home_ing from './element2/Home_ing';
import Element4 from './element4/Element4';
import Realisation from './projet/realisation';
import './accueil.css';



class Accueil extends Component {

    componentDidMount = () =>{
        document.title = "SAI Isolation";
    }

    render(){
    return (
        <>
        <div className="slider"><Slide2/></div>
        <Qs/>
        <Home_ing/>
        <Realisation/>   
        <Element4/>
        
        
         </>
        );
    }
}
export default Accueil;
