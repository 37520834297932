import React from 'react';
import './home_ing.css';
import { NavLink } from 'react-router-dom';
import isolation from './isolation.png'



const Home_ing = (props) => (
    <>
        <div className="root_home_ing" >
            <div className="filtre_hi">
                <div className="entete_hi">
                    <div className="title_hi">Ingenierie</div>
                    <div className="desc_hi">SAI et son équipe compétente offrent tout leur savoir-faire en</div>
                </div>
                <div className="activite">

                    <div className="thermique_hi">
                        <i class="fas fa-thermometer-quarter icon"></i>
                        <div className="secteur_hi">
                            <NavLink to="/thermique" exact><div className="link">Thermique</div></NavLink>
                        </div>
                    </div>

                    <div className="frigorifique_hi">
                        <i class="fas fa-snowflake icon"></i>
                        <div className="secteur_hi"><NavLink to="/frigorifique" exact><div className="link">Frigorifique</div></NavLink></div>
                    </div>

                    <div className="calorifugeage_hi">
                        <i class="fas fa-fire icon"></i>
                        <div className="secteur_hi"><NavLink to="/calorifugeage" exact><div className="link">Calorifugeage</div></NavLink></div>
                    </div>
                    <div className="acoustique_hi">
                        <i class="fas fa-fan icon"></i>
                        <div className="secteur_hi"><NavLink to="/aeraulique" exact><div className="link">Aeraulique</div></NavLink></div>
                    </div>

                    <div className="aerolique_hi">
                        <i class="fas fa-industry icon"></i>
                        <div className="secteur_hi"><NavLink to="/acoustique"><div className="link">Acoustique</div></NavLink></div>
                    </div>
                </div>

            </div>
        </div>

    </>
);

export default Home_ing;